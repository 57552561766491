<script setup lang="ts">
  import {useOnline, useNetwork} from '@vueuse/core';
  import {useUXNavigationStore} from '~/stores';
  import {downloadApp} from '~/utils';

  const {t} = useI18n();
  const uxNavigationStore = useUXNavigationStore();

  const online = useOnline();
  const authState = useCpUser();
  const route = useRoute();
  const config = useRuntimeConfig();

  const showSignInModal = ref(false);
  const modalTimeout = ref<any>();

  const {downlink} = useNetwork();
  const isSlowNetwork = computed(() => (downlink.value && downlink.value < 1) && online.value && !config.public.DISABLE_CONNECTION_ALERTS);
  const hideSlowNetworkNotification = ref(false);
  const showNoNetworkNotification = ref(false);
  const showOfflineState = ref(false);
  const loadSignInModal = ref(false);
  const disableDownloadModal = useCookie<boolean>('disableDownloadModal');
  disableDownloadModal.value = disableDownloadModal.value ?? false;

  if (process.client) {
    useListen('show-offline-state', () => {
      showOfflineState.value = true;
    });
  }

  watch(() => online.value, () => {
    if (!online.value && !config.public.DISABLE_CONNECTION_ALERTS) {
 showNoNetworkNotification.value = true;
}
  });

 const handleEvent = (fromNotification = false) => {
    if (fromNotification) {
      showNoNetworkNotification.value = false;
    } else {
      showOfflineState.value = false;
    }
    useEvent('offline-retry-action');
  };

  const navHeaderOptions = computed(() => {
    if (route.meta.navHeaderOptionsAuth && authState.user.value) {
      return {
        ...route.meta.navHeaderOptionsAuth,
        headline: route.meta.navHeaderOptionsAuth?.headline?.replace('{{name}}', getShortName(authState.user.value?.name)),
      };
    }

    return route.meta.navHeaderOptions;
  });

  if (process.client) {
    useListen('show-login-modal', (props) => {
      if (modalTimeout.value) {
        clearTimeout(modalTimeout.value);
      }
      showSignInModal.value = props.show;
      if (props.show) {
        // @ts-ignore-next-line
        if (props.route) {
 authState.setRouteData(props.route, props.params, props.query);
} else {
 authState.resetRouteData();
}
      }
    });
  }

  onMounted(() => {
    /**
     * This code helps us delay loading the sign-in modal to prioritize loading other resources first.
     * This prevents the sign-in modal from slowing down the initial page load.
     * The Apple and Google scripts are downloaded when the sign-in modal becomes visible.
     * So, this timer loads the sign-in modal after 8 seconds,
     * or if the user clicks the 'My Account' icon and opens the sign-in modal, it will be downloaded.
     */
    modalTimeout.value = setTimeout(() => {
      loadSignInModal.value = true;
    }, 8000);
  });

  onBeforeUnmount(() => {
      clearTimeout(modalTimeout.value);
  });

</script>

<template>
    <v-app theme="cpMobile" class="cp-home-layout">
        <CpHeader
            v-if="route.meta.navHeaderOptions || route.meta.navHeaderOptionsAuth"
            class="cp-home-layout__header"
            v-bind="navHeaderOptions"
        />
        <main id="main-content" class="cp-home-layout__main" data-testid="home-layout-main">
            <ClientOnly>
                <div class="cp-home--layout__notification">
                    <LazyCpNotificationBanner
                        v-if="isSlowNetwork && !hideSlowNetworkNotification"
                        kind="weakConnection"
                        icon="wifi"
                        @notification-banner-event="hideSlowNetworkNotification = true"
                    />
                    <LazyCpNotificationBanner
                        v-if="showNoNetworkNotification"
                        kind="lostConnection"
                        icon="wifi"
                        @notification-banner-event="handleEvent(true)"
                    />
                </div>
                <LazyCpEmptyState
                    v-if="showOfflineState"
                    icon="wifi_off"
                    title="¡Se ha perdido la conexión a internet!"
                    button-label="Reintentar"
                    @empty-state-action="handleEvent"
                >
                    <template #body>
                        <CpText variant="subheading" type="neutral-dark" class="cp-home-layout__text">
                            {{ t('advices') }}
                        </CpText>
                        <CpText variant="body-regular" type="neutral-dark" class="cp-home-layout__text">
                            ·  {{ t('checkYourConnection') }}
                        </CpText>
                        <CpText variant="body-regular" type="neutral-dark" class="cp-home-layout__text">
                            · {{ t('pressTheNextButton') }}
                        </CpText>
                    </template>
                </LazyCpEmptyState>
            </ClientOnly>
            <div v-if="online" class="cp-home-layout__main-slot">
                <slot />
            </div>
        </main>
        <CpBottomNavMenu v-if="route.meta.menuSelected" :active="route.meta.menuSelected" />
        <ClientOnly>
            <LazyCpModalSignIn v-if="loadSignInModal || showSignInModal" v-model:show="showSignInModal" />
            <LazyCpModalAppDownload
                v-if="!disableDownloadModal"
                v-model:show="uxNavigationStore.showDownloadModal"
                @update:show="uxNavigationStore.showDownloadModal = $event"
                @download="downloadApp()"
            />
        </ClientOnly>
    </v-app>
</template>

<style scoped lang="scss">
.cp-home-layout {
  height: 100dvh;
  display: flex;

  &__header {
    z-index: 50;
  }
  &__main {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  &__main-slot {
    flex: 1;
    max-height: 100%;
  }
  &__notification {
    background-color: white;
    position: fixed;
    z-index: 10;
  }
  &__text {
    margin-bottom: 8px
  }
}
</style>

<i18n locale="es-US" lang="yaml">
  checkYourConnection: "Revisa tu conexión para seguir navegando."
  pressTheNextButton: "O presiona el siguiente botón."
  advices: 'Consejos:'
</i18n>
